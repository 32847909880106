import React from 'react'
import ReactPlayer from 'react-player'

import Layout from '../../components/layout'
import LightBox from '../../components/lightbox/lightbox'
import OtherLinks from '../../components/otherLinks/otherLinks'

import p1 from '../../images/products/HRPortal/HR Portal Homepage.jpg'
import p2 from '../../images/products/HRPortal/HR Portal Meine Daten Personalien.jpg'
import p3 from '../../images/products/HRPortal/HR Portal Meine Daten Anstellung Organisation.jpg'
import p4 from '../../images/products/HRPortal/HR Portal Meine Daten Kinder.jpg'
import p5 from '../../images/products/HRPortal/HR Portal Meine Daten Dossier.jpg'
import p6 from '../../images/products/HRPortal/HR Portal Meine Daten Dossier Lohnabrechnung.jpg'
import p7 from '../../images/products/HRPortal/HR Portal Aufgabenliste.jpg'
import p8 from '../../images/products/HRPortal/HR Portal Stellvertretung festlegen.jpg'

const Prozessmanagement = () => (
    <Layout>
        <section id="prozessmanagement" className="section-wrapper">
            <header className="section-header">
                <h3>HR Portal</h3>
            </header>
            <div className="post">
                <h3>Employee Self Service, Management Self Service</h3>
                <p>
                    Moderne HR-Abteilungen treten heute über ein HR Portal an Vorgesetze und Mitarbeitende heran. Prozesse werden direkt und kontrolliert an
                    den „Entstehungsort“ übergeben. Arbeitsprozesse werden effizient und ohne Medienbrüche abgehandelt. Abacus verfügt über ein standardisiertes
                    HR Portal, das man auch sehr gut auf individuelle Bedürfnisse des Kunden anpassen kann.
                </p>
                <br />
                <LightBox images={overviewImages} thumbnailHeight={75} />
                <br /><br />
                <p>
                    Inhalte eines HR Portals können sein: Reportings auf Basis von Dashboards, eigene Mitarbeiterdaten
                    einsehen und ändern, Zeiterfassung, Absenzenmanagement, Zeugnisprozess, Personalkosten-Budgetierungsprozess,
                    Visualisierung der Firmenorganisation (Stellenplanung, Organigramm)
                </p>
            </div>

            <div className="post section-with-vid" id="video">
                <div className="vid">
                    <ReactPlayer url="https://www.youtube.com/watch?v=QKdtBfSA7xQ" controls={true} height="100%" width="100%" />
                </div>
            </div>

            <div className="post section-with-vid" id="video3">
                <div className="vid">
                    <ReactPlayer url="https://www.youtube.com/watch?v=F9H-b6bFQds" controls={true} height="100%" width="100%" />
                </div>
            </div>


            <OtherLinks links={links} />
        </section>
    </Layout>
)

const overviewImages = [
    {
        index: 0,
        src: p1,
        title: 'Homepage',
        description: 'Der User wird im HR Portal begrüsst.'
    },
    {
        index: 1,
        src: p2,
        title: 'Meine Daten - Personalien',
        description: 'Der User kann seine Personalien einsehen oder ändern (Mutationsprozess starten).'
    },
    {
        index: 2,
        src: p3,
        title: 'Meine Daten - Anstellung & Organisation',
        description: 'Die Daten aus der Anstellung und der Organisation können eingesehen werden.'
    },
    {
        index: 3,
        src: p4,
        title: 'Meine Daten - Kinder',
        description: 'Die Informationen zu den Kinderdaten inkl. Mutationsprozessen können eingesehen werden.'
    },
    {
        index: 4,
        src: p5,
        title: 'Meine Daten - Dossier',
        description: 'Jedem HR Portal User kann Zugriff auf sein Personaldossier gewährt werden.'
    },
    {
        index: 5,
        src: p6,
        title: 'Meine Daten - Dossier - Lohnabrechnung',
        description: 'Die monatliche Lohnabrechnung kann via HR Portal und Personaldossier an den Mitarbeitenden verteilt werden.'
    },
    {
        index: 6,
        src: p7,
        title: 'Aufgabenliste',
        description: 'Via Aufgabenliste wird der User über Aktivitäten bei den Prozessen aufmerksam gemacht.'
    },
    {
        index: 7,
        src: p8,
        title: 'Stellvertretung festlegen',
        description: 'Die Stellvertretung im Portal kann pro Rolle zeitlich delegiert werden.'
    },

];

const links = [
    {
        name: 'Abacus HR Portal «MyAbacus»',
        desc: 'Abacus HR Portal «MyAbacus»',
        link: 'https://hr-portal.ch/'
    },
    {
        name: 'Abacus Mitarbeiterportal',
        desc: 'Abacus Mitarbeiterportal',
        link: 'https://hr-portal.ch/mitarbeiterportal/'
    },
    {
        name: 'Abacus Personaldossier',
        desc: 'Abacus Personaldossier',
        link: 'https://hr-portal.ch/personalverwaltung/#personaldossier'
    }
]

export default Prozessmanagement
